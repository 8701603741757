import React from 'react';

export const Asia = {
    "name": "Asia",
    "link": "asia",

    "thumb": "https://live.staticflickr.com/65535/50380543116_afc0366603_t.jpg",

    "images": [
        "https://live.staticflickr.com/65535/50380543116_52cfc6d692_o.jpg",
        "https://cdn.pixabay.com/photo/2017/11/28/13/07/sunset-2983614_1280.jpg",
        "https://images.unsplash.com/photo-1506781961370-37a89d6b3095?ixlib=rb-1.2.1&w=1280&q=80",
        "https://images.unsplash.com/photo-1510001618818-4b4e3d86bf0f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1280&q=80",
        "https://cdn.pixabay.com/photo/2018/02/25/21/34/bada-bagh-3181803_1280.jpg"
    ],

    "description": "Asia is a continent that features a massive diversity of cultures and religions. From the street food stalls of Vietnam and Thailand, to the tallest mountains of the world on Nepal, from the ancient civilizations of India and their history to the paradise destinations of Indonesia and Philippines, Asia is a massive continent that offers incredible experiences to every person who dares to visit it. What are you waiting on for your next adventure?",

    "destinations": [
        {
            "name": "Vietnam",
            "link": "vietnam",
            "image": "https://live.staticflickr.com/65535/50493711258_4c9594632a_o.jpg"
        }
    ]
}
