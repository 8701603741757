import React from 'react';
import ContinentGuide from '../../../components/guides/ContinentComponent';
import Footer from '../../../components/FooterComponent';

import { Asia } from '../../../guides/continents/asia';

function AsiaPage(props) {
    return (
        <div>
            <ContinentGuide continent={ Asia } pathname={ props.location.pathname } />
            <Footer />
        </div>
    );
}

export default AsiaPage;
